exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-maintenance-jsx": () => import("./../../../src/pages/maintenance.jsx" /* webpackChunkName: "component---src-pages-maintenance-jsx" */),
  "component---src-pages-mycomponents-jsx": () => import("./../../../src/pages/mycomponents.jsx" /* webpackChunkName: "component---src-pages-mycomponents-jsx" */),
  "component---src-pages-single-report-jsx": () => import("./../../../src/pages/single-report.jsx" /* webpackChunkName: "component---src-pages-single-report-jsx" */),
  "component---src-pages-testform-jsx": () => import("./../../../src/pages/testform.jsx" /* webpackChunkName: "component---src-pages-testform-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-event-jsx": () => import("./../../../src/templates/event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-report-jsx": () => import("./../../../src/templates/report.jsx" /* webpackChunkName: "component---src-templates-report-jsx" */),
  "component---src-templates-universalnew-js": () => import("./../../../src/templates/universalnew.js" /* webpackChunkName: "component---src-templates-universalnew-js" */)
}

