import React from 'react';
// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/normalize.css"

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
// custom CSS styles
import "./src/css/style.css"

import MaintenanceChecker from './src/components/MaintenanceChecker'

export const wrapPageElement = ({ element }) => {
    return <MaintenanceChecker>{element}</MaintenanceChecker>;
};



export const onRouteUpdate = () => {
    const script2 = document.createElement("script");
    script2.src = "https://doublethedonation.com/api/js/ddplugin.js";
    //console.log('browser');
    document.body.appendChild(script2);
};
